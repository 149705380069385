import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Play } from '../icons';

interface Props {
  data: {
    allMarkdownRemark: {
      edges: {
        node: {
          excerpt: string;
          fields: {
            readingTime: {
              text: string;
            };
            slug: string;
          };
          frontmatter: {
            date: string;
            description: string;
            title: string;
          };
        };
      }[];
    };
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
  location: {
    pathname: string;
  };
}

const VideoIndex = ({ data, location }: Props) => {
  const siteTitle = data.site.siteMetadata.title;
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <div className="outer">
        <div className="inner">
          <h1>All Videos</h1>
          <div className="post-feed">
            {/* FIXME: make this about videos */}
            {posts.map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug;

              return (
                <article className="post-card post">
                  <Link to={node.fields.slug} className="post-card-image-link">
                    <img
                      className="post-card-image"
                      sizes="(max-width: 1000px) 400px, 700px"
                      src="https://static.ghost.org/v2.0.0/images/welcome-to-ghost.jpg"
                      alt={title}
                    />
                    <Play
                      style={{
                        position: 'absolute',
                        top: '40%',
                        right: '42%',
                        color: 'white',
                        width: 50,
                      }}
                    />
                  </Link>
                  <div className="post-card-content">
                    <Link
                      to={node.fields.slug}
                      className="post-card-content-link"
                    >
                      <header className="post-card-header">
                        <span className="post-card-tags">
                          {node.frontmatter.date}
                        </span>
                        <h2 className="post-card-title">{title}</h2>
                      </header>
                    </Link>
                    <footer className="post-card-meta">
                      {/* FIXME: figure out what we want to put here */}
                      <div className="author-list" />
                    </footer>
                  </div>
                </article>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default VideoIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            readingTime {
              text
            }
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
